class Wcag {
    body;
    fontSizeButtonActiveClass = 'accessibility-container__font-button--active';
    contrastButtonActiveClass = 'accessibility-container__contrast-button--active';
    fontSizeButtons;
    fontSizeButtonsSm;
    fontSizeButtonsLg;
    fontSizeButtonsXl;
    contrastButtons;
    fontSizeCookieName = 'nilfs';
    contrastCookieName = 'nilcon';

    constructor() {
        this.body = document.querySelector('body');
        this.fontSizeButtons = document.querySelectorAll('.accessibility-container__font-button');
        this.fontSizeButtonsSm = document.querySelectorAll('.accessibility-container__font-button--sm');
        this.fontSizeButtonsLg = document.querySelectorAll('.accessibility-container__font-button--lg');
        this.fontSizeButtonsXl = document.querySelectorAll('.accessibility-container__font-button--xl');
        this.contrastButtons = document.querySelectorAll('.accessibility-container__contrast-button');
        this.initialize();
    }

    initialize() {
        this.initializeBodyClasses();
        this.initializeContrastButton();
        this.initializeFontSizeButtons();
        this.fixMobileFontSize();
    }

    getCookieValue(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    setCookieValue(name, value, days = 365) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=0; path=/;';
    }

    fixMobileFontSize() {
        let current = this;

        current.removeFontSizeClassesOnMobile();
        current.removeFontSizeCookieOnMobile();

        window.addEventListener('resize', function () {
            current.removeFontSizeClassesOnMobile();
            current.removeFontSizeCookieOnMobile();
        });
    }

    removeFontSizeClassesOnMobile() {
        let width = window.innerWidth;

        if (width < 1200) {
            this.body.classList.remove('body--fs-1');
            this.body.classList.remove('body--fs-2');
        }
    }

    removeFontSizeCookieOnMobile() {
        let width = window.innerWidth;

        if (width < 1200) {
            this.eraseCookie(this.fontSizeCookieName);
        }
    }

    initializeBodyClasses() {
        this.updateBodyFontSizeClass(this.getCookieValue(this.fontSizeCookieName));
        this.updateBodyContrastClass(this.getCookieValue(this.contrastCookieName) === '1');
    }

    updateBodyFontSizeClass(fontSize) {
        let fontSizeButtonActiveClass = this.fontSizeButtonActiveClass;

        if (fontSize === '0' || fontSize === false || fontSize === undefined || fontSize === '') {
            this.body.classList.remove('body--fs-1');
            this.body.classList.remove('body--fs-2');
            this.fontSizeButtonsLg.forEach(function(button) {
               button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsXl.forEach(function(button) {
                button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsSm.forEach(function(button) {
                button.classList.add(fontSizeButtonActiveClass);
            });
        } else if (this.getCookieValue('nilfs') === '1') {
            this.body.classList.remove('body--fs-2');
            this.body.classList.add('body--fs-1');
            this.fontSizeButtonsSm.forEach(function(button) {
                button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsXl.forEach(function(button) {
                button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsLg.forEach(function(button) {
                button.classList.add(fontSizeButtonActiveClass);
            });
        } else if (this.getCookieValue('nilfs') === '2') {
            this.body.classList.remove('body--fs-1');
            this.body.classList.add('body--fs-2');
            this.fontSizeButtonsSm.forEach(function(button) {
                button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsLg.forEach(function(button) {
                button.classList.remove(fontSizeButtonActiveClass);
            });
            this.fontSizeButtonsXl.forEach(function(button) {
                button.classList.add(fontSizeButtonActiveClass);
            });
        }
    }

    updateBodyContrastClass(isContrast) {
        let labelContrastOff = this.contrastButtons[0].dataset.contrastOff,
            labelContrastOn = this.contrastButtons[0].dataset.contrastOn,
            contrastButtonActiveClass = this.contrastButtonActiveClass;

        if (isContrast) {
            this.body.classList.add('body--contrast');
            this.contrastButtons.forEach(function(button) {
                button.setAttribute('aria-label', labelContrastOff);
                button.classList.add(contrastButtonActiveClass);
            });
        } else {
            this.body.classList.remove('body--contrast');
            this.contrastButtons.forEach(function(button) {
                button.setAttribute('aria-label', labelContrastOn);
                button.classList.remove(contrastButtonActiveClass);
            });
        }
    }

    initializeContrastButton() {
        let current = this;

        this.contrastButtons.forEach(function(button) {
            button.addEventListener('click', function (e) {
                e.preventDefault();

                if (current.body.classList.contains('body--contrast')) {
                    current.body.classList.remove('body--contrast');
                    current.eraseCookie(current.contrastCookieName);
                    this.setAttribute('aria-label', this.dataset.contrastOn);
                    this.classList.remove(current.contrastButtonActiveClass);
                } else {
                    current.body.classList.add('body--contrast');
                    current.setCookieValue(current.contrastCookieName, '1');
                    this.setAttribute('aria-label', this.dataset.contrastOff);
                    this.classList.add(current.contrastButtonActiveClass);
                }
            });
        });
    }

    removeActiveClassFromFontSizeButtons() {
        this.fontSizeButtons.forEach(item => {
            item.classList.remove(this.fontSizeButtonActiveClass);
        });
    }

    initializeFontSizeButtons() {
        let current = this;

        this.fontSizeButtonsSm.forEach(function(button) {
            button.addEventListener('click', function (e) {
                e.preventDefault();

                current.removeActiveClassFromFontSizeButtons();
                this.classList.add(current.fontSizeButtonActiveClass);

                if (current.body.classList.contains('body--fs-1') || current.body.classList.contains('body--fs-2')) {
                    current.body.classList.remove('body--fs-1');
                    current.body.classList.remove('body--fs-2');
                    current.eraseCookie(current.fontSizeCookieName)
                }
            });
        });

        this.fontSizeButtonsLg.forEach(function(button) {
            button.addEventListener('click', function (e) {
                e.preventDefault();

                current.removeActiveClassFromFontSizeButtons();
                this.classList.add(current.fontSizeButtonActiveClass);

                if (current.body.classList.contains('body--fs-2')) {
                    current.body.classList.remove('body--fs-2');
                }

                current.body.classList.add('body--fs-1');
                current.setCookieValue(current.fontSizeCookieName, '1');
            });
        });

        this.fontSizeButtonsXl.forEach(function(button) {
            button.addEventListener('click', function (e) {
                e.preventDefault();

                current.removeActiveClassFromFontSizeButtons();
                this.classList.add(current.fontSizeButtonActiveClass);

                if (current.body.classList.contains('body--fs-1')) {
                    current.body.classList.remove('body--fs-1');
                }

                current.body.classList.add('body--fs-2');
                current.setCookieValue(current.fontSizeCookieName, '2');
            });
        });
    }
}

function initializeWcag() {
    const wcag = new Wcag();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeWcag);
} else {
    initializeWcag();
}